import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Product } from "src/app/shared/models/product";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserSyngenta } from "src/app/shared/models/usuarios";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  getUsers(): Observable<UserSyngenta[]> {
    return this.http
      .get<any>(`${this.url}/users/getallusers`)
      .pipe(map(users => (users = users.Items)));
  }
}
