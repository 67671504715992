import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Product } from "src/app/shared/models/product";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Cultivo } from "src/app/shared/models/cultivos";
import { Catalog } from "src/app/shared/models/catalogs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CatalogosService {
  url = environment.url;
  // url = "http://127.0.0.1:3000";

  constructor(private http: HttpClient) {}

  // Get Methods for all the parameters
  getEnfermedades(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/catalogos/getallenfermedades`)
      .pipe(map((p) => (p = p.Items)));
  }

  getPlagas(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/catalogos/getallplagas`)
      .pipe(map((p) => (p = p.Items)));
  }

  getMalezas(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/catalogos/getallmalezas`)
      .pipe(map((p) => (p = p.Items)));
  }

  getNutriciones(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/catalogos/getallnutricion`)
      .pipe(map((p) => (p = p.Items)));
  }

  getCultivos(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/catalogos/getallcultivos`)
      .pipe(map((p) => (p = p.Items)));
  }

  // Post methods for add parameters

  addEnfermedad(enfermedad: Catalog, uid) {
    const arrayInfoObj = [{ name: enfermedad, uid: uid }];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint enfermedades");
    return this.http.post<any>(
      `${this.url}/catalogos/saveenfermedades`,
      formData
    );
  }

  addPlaga(plaga: Catalog, uid) {
    const arrayInfoObj = [{ name: plaga, uid: uid }];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint plagas");
    return this.http.post<any>(`${this.url}/catalogos/saveplagas`, formData);
  }

  addMaleza(maleza: Catalog, uid) {
    const arrayInfoObj = [{ name: maleza, uid: uid }];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint maleza");
    return this.http.post<any>(`${this.url}/catalogos/savemalezas`, formData);
  }

  addNutricion(nutricion: Catalog, uid) {
    const arrayInfoObj = [{ name: nutricion, uid: uid }];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint nutricion");
    return this.http.post<any>(`${this.url}/catalogos/savenutricion`, formData);
  }

  addCultivo(cultivo: Cultivo) {
    const arrayInfoObj = [cultivo];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    return this.http.post<any>(`${this.url}/catalogos/savecultivo`, formData);
  }
}
