import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ProductsService } from "./home/products/products.service";
import { CatalogosService } from "./home/catalogos/catalogos.service";
import { UsersService } from "./home/users/users.service";
import { NoticiasService } from "./home/noticias/noticias.service";
import { EventosService } from "./home/eventos/eventos.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    ProductsService,
    CatalogosService,
    UsersService,
    NoticiasService,
    EventosService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
