import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserSyngenta } from "../shared/models/usuarios";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  private currentUserSource = new BehaviorSubject<UserSyngenta>(null);
  currentUser = this.currentUserSource.asObservable();
  user: UserSyngenta;
  redirectUrl: string;

  // url = "https://8ubr8vgum5.execute-api.us-east-1.amazonaws.com/Prod";
  url = environment.url;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  get isLoggedIn(): boolean {
    return !!this.user;
  }

  updateUser(user: UserSyngenta) {
    this.currentUserSource.next(user);
    this.currentUser.subscribe((user) => {
      this.user = user;
      console.log(user);
      this.router.navigate(["/home"]);
    });
  }

  login(email, password): Observable<any> {
    return this.http.post<any>(`${this.url}/users/login`, {
      email_address_str: email,
      attempted_password_str: password,
      dbName: "appsoluciones-usuarios-prod",
    });
  }

  logout(): void {
    this.currentUser = null;
  }
}
