import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Evento } from "src/app/shared/models/evento";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventosService {
  url = environment.url;
  // url = "http://127.0.0.1:3000";

  constructor(private http: HttpClient) {}

  addEvento(evento: Evento, uid) {
    evento.uid = uid;
    const arrayInfoObj = [evento];
    // debugger;
    console.log(evento);
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint noticias");
    return this.http.post<any>(`${this.url}/events/addevents`, formData);
  }

  getEventos(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/events/getallevents`)
      .pipe(map((p) => (p = p.Items)));
  }
}
