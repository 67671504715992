import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./user/auth.guard";
import { SelectiveStrategy } from "./selective-strategy.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

const routes: Routes = [
  { path: "login", loadChildren: "./login/login.module#LoginModule" },
  {
    path: "",
    loadChildren: "./home/home.module#HomeModule",
    data: { preload: true },
    canActivate: [AuthGuard]
  },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: SelectiveStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
