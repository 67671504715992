import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Product } from "src/app/shared/models/product";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  getProducts(): Observable<Product[]> {
    return this.http
      .get<any>(`${this.url}/products/getallproducts`)
      .pipe(map((p) => (p = p.Items)));
  }

  addProduct(product) {
    const arrayInfoObj = [product];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));

    return this.http.post<any>(`${this.url}/products/saveproduct`, {
      info: JSON.stringify(product),
    });
  }

  editProduct(product) {
    console.log("recibiendo --- ", product);
    return this.http.post<any>(`${this.url}/products/saveproduct`, {
      info: JSON.stringify(product),
    });
  }
}
