import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Noticia } from "src/app/shared/models/noticias";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NoticiasService {
  url = environment.url;
  // url = "http://127.0.0.1:3000";

  constructor(private http: HttpClient) {}

  addNew(noticia: Noticia, uid) {
    noticia.uid = uid;
    const arrayInfoObj = [noticia];
    const formData = new FormData();
    formData.append("info", JSON.stringify(arrayInfoObj));
    console.log("probar endpoint noticias");
    console.log(noticia);
    return this.http.post<any>(`${this.url}/noticias/addnoticias`, formData);
  }

  getNoticias(): Observable<any> {
    return this.http
      .get<any>(`${this.url}/noticias/getallnoticias`)
      .pipe(map((p) => (p = p.Items)));
  }

  deleteNoticia(noticia: Noticia): any {
    const formData = new FormData();
    formData.append("uid", noticia.uid);
    return this.http.post<any>(`${this.url}/noticias/deletenoticias`, formData);
  }
}
